/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  errors: {},
  error: ''
});

export default function editAddViewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_EDIT_ADD_VIEW_MODAL': {
      return INITIAL_STATE.set('isShowing', true);
    }
    case 'EDIT_ADD_VIEW_MODAL_FORM_ERRORS': {
      return state.set('errors', fromJS(action.payload));
    }
    case 'HIDE_EDIT_ADD_VIEW_MODAL': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
